import { Manifest, JsFileExp } from './constants';

export class Launcher {
  constructor(settings) {
    this.ref = null;

    this.state = {
      settings: settings,
    };
  }

  async boot() {
    if (this.isLoaded()) {
      return;
    }

    const frame = await createReactFrame();
    this.ref = frame;
  }

  shutdown() {
    if (this.ref instanceof Element) {
      this.ref.remove();
    }
    this.ref = null;
  }

  update(settings) {
    this.state.settings = {
      ...this.state.settings,
      ...settings,
    };
  }

  isLoaded() {
    return this.ref instanceof Element && this.ref.getRootNode() === document;
  }
}

async function createReactFrame() {
  const frame = document.createElement('iframe');
  frame.id = 'nucleus-launcher';
  frame.setAttribute(
    'style',
    'position: absolute !important; opacity: 0 !important; width: 1px !important; height: 1px !important; top: 0 !important; left: 0 !important; border: none !important; display: block !important; z-index: -1 !important; pointer-events: none;'
  );
  frame.setAttribute('aria-hidden', 'true');
  frame.setAttribute('tabIndex', '-1');
  frame.setAttribute('title', 'Nucleus Launcher');
  document.body.appendChild(frame);

  // Bugfix for Firefox is to use a timeout after appendChild...
  // This is because the action is async, and firefox will replace
  // our frame with about:blank after we write to it
  // https://bugzilla.mozilla.org/show_bug.cgi?id=297685
  await new Promise((resolve, reject) =>
    setTimeout(() => {
      frame.contentDocument.documentElement.innerHTML = `<!doctype html><html lang="en"><head></head><body></body></html>`;
      const inlineElement = createInlineScriptElement(`WEBPACK_MANIFEST=${JSON.stringify(Manifest)}`);
      frame.contentDocument.head.appendChild(inlineElement);
      loadManifestFiles(frame.contentDocument.head, Manifest);
      resolve();
    }, 1)
  );

  return frame;
}

function createScriptElement(src) {
  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.charset = 'utf-8';
  el.src = src;
  return el;
}

function createInlineScriptElement(content) {
  const el = document.createElement('script');
  el.text = content;
  return el;
}

function loadManifestFiles(target, manifest) {
  Object.keys(manifest).forEach((key) => {
    if (JsFileExp.test(key) === true) {
      const scriptElement = createScriptElement(manifest[key]);
      target.appendChild(scriptElement);
    }
  });
}
