import { Launcher } from './Launcher';

const init = () => {
  const settings = window.nucleusLauncherSettings;

  if (settings === undefined) {
    console.warn('Warning: Nucleus Launcher settings are missing');
    return;
  }

  if (window.NucleusLauncher === undefined) {
    window.NucleusLauncher = new Launcher(settings);
    window.addEventListener('pagehide', async () => {
      window.NucleusLauncher.shutdown();
      window.NucleusLauncher.boot();
    });
  } else {
    window.NucleusLauncher.update(settings);
  }

  if (window.document.readyState === 'loading') {
    window.document.addEventListener('DOMContentLoaded', () => window.NucleusLauncher.boot());
  } else {
    window.NucleusLauncher.boot();
  }
};

init();
